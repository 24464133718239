import { handleResponse, handleError } from "./apiUtils";
import * as tc from "../timeline/Constants";

function getAuthHeader(auth) {
  let headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(auth.un + ":" + auth.pw));
  return headers;
}
export function getFavoriteFilters(auth) {
  let url = "https://jira.sensio.no/rest/api/2/filter/favourite";

  let headers = getAuthHeader(auth);

  console.log("ticketsAPI.getFavoriteFilters", url);
  return fetch(url, { method: "GET", headers: headers }).then(handleResponse).catch(handleError);
}

export function getPlannedItems(auth) {
  let url = tc.JIRA_PLANNED_QUERY;

  let headers = getAuthHeader(auth);

  console.log("ticketsAPI.getPlannedItems", url);
  return fetch(url, { method: "GET", headers: headers }).then(handleResponse).catch(handleError);
}

export function verifyLogin(auth) {
  let url = "https://jira.sensio.no/rest/api/2/mypermissions";

  let headers = getAuthHeader(auth);

  console.log("ticketsAPI.verifyLogin", url);
  return fetch(url, { method: "GET", headers: headers }).then(handleResponse).catch(handleError);
}

export function getItems(auth, url) {
  let headers = getAuthHeader(auth);

  console.log("ticketsAPI.getPlannedItems", url);
  return fetch(url, { method: "GET", headers: headers }).then(handleResponse).catch(handleError);
}

export function updateItem(auth, item, updates) {
  if (item && item !== "") {
    let headers = getAuthHeader(auth);
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const url = tc.JIRA_BASE_URI + tc.JIRA_UPDATE_ISSUE_API + item;

    const c = JSON.stringify(updates);
    console.log("ticketsAPI.updateItem", { headers, url, updates, c });
    return fetch(url, { method: "PUT", headers: headers, body: c })
      .then(handleResponse)
      .catch(handleError);
  } else {
    throw new Error("Mssing item in updateItem");
  }
}
